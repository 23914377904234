<style module></style>
<template>
  <q-list bordered v-if="currentPage && currentPage.type === 'questions'">
    <q-item-label header>Questions</q-item-label>
    <draggable
      v-model="currentPage.data.questions"
      handle=".question.q-expansion-item .q-item"
      item-key="internalID"
    >
      <template #item="{ element: question, index: qIndex }">
        <q-expansion-item
          expand-separator
          class="question"
          icon="question_mark"
          :label="question.body"
          :caption="question.id"
          group="questions"
          @show="onShow($event)"
          @after-show="afterShow()"
        >
          <q-card>
            <q-card-actions align="right">
              <q-btn
                @click="removeQuestion(currentPage, qIndex)"
                icon="remove"
                label="Supprimer la question"
                color="negative"
                outline
              />
              <q-btn
                @click="duplicateQuestion(currentPage, qIndex)"
                icon="content_copy"
                label="dupliquer la question"
                color="primary"
                outline
              />
            </q-card-actions>
            <q-card-section>
              <q-input
                v-model="question.id"
                label="Identifiant de la question"
              ></q-input>
              <q-editor v-model="question.body" min-height="5rem" />
              <q-select
                :options="conditionalOptions"
                v-model="question.condition.questionID"
                :emit-value="true"
                :map-options="true"
                label="Conditionnée par la réponse à la question"
              />
              <q-list bordered v-if="question.condition.questionID">
                <q-item-label header>Réponses acceptées</q-item-label>
                <q-item
                  v-for="(
                    acceptedAnswerInternalID, acceptedAnswerIndex
                  ) in question.condition.answers"
                  :key="acceptedAnswerInternalID"
                >
                  <q-select
                    :options="
                      getAcceptedAnswersOptions(question.condition.questionID)
                    "
                    v-model="question.condition.answers[acceptedAnswerIndex]"
                    :emit-value="true"
                    :map-options="true"
                    label="Réponse"
                  />
                  <q-btn
                    class="gt-xs"
                    size="12px"
                    flat
                    dense
                    round
                    icon="delete"
                    @click="
                      removeAcceptedAnswer(
                        question.condition.answers,
                        acceptedAnswerIndex,
                      )
                    "
                  >
                    <q-tooltip>Supprimer</q-tooltip>
                  </q-btn>
                </q-item>
                <q-item>
                  <q-btn
                    @click="addConditionalAnswer(question.condition.answers)"
                    icon="add"
                    label="Réponse acceptée"
                    outline
                  />
                </q-item>
              </q-list>
            </q-card-section>
            <q-card-section v-if="question.type === 'multichoice'">
              <q-list bordered>
                <q-item-label header>Réponses possibles</q-item-label>
                <draggable
                  v-model="question.answers"
                  handle=".answer.q-expansion-item .q-item"
                  item-key="internalID"
                >
                  <template #item="{ element: answer, index }">
                    <q-expansion-item
                      group="questions-answers"
                      expand-separator
                      icon="fact_check"
                      class="answer"
                      :label="answer.body"
                      :caption="answer.code"
                    >
                      <q-card>
                        <q-card-actions align="right">
                          <q-btn
                            @click="removeAnswer(question, index)"
                            icon="remove"
                            label="Supprimer la réponse"
                            color="negative"
                            outline
                          />
                        </q-card-actions>
                        <q-card-section>
                          <q-input
                            v-model="answer.body"
                            label="Réponse à afficher"
                          ></q-input>
                          <q-input
                            v-model="answer.code"
                            label="Code interne"
                          ></q-input>
                        </q-card-section>
                      </q-card>
                    </q-expansion-item>
                  </template>
                </draggable>

                <q-separator />
                <q-item>
                  <q-btn
                    @click="addAnswer(question)"
                    icon="add"
                    label="Réponse"
                    outline
                  />
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </template>
    </draggable>
    <q-item class="justify-around">
      <q-btn
        @click="addMultiChoiceQuestion()"
        color="primary"
        icon="add"
        label="Question multichoix"
        size="l"
        outline
        icon-right="task_alt"
      />
      <q-btn
        @click="addFreeTextQuestion()"
        color="primary"
        icon="add"
        label="Question à réponse text libre"
        size="l"
        outline
        icon-right="keyboard"
      />
      <q-btn
        @click="addNumberQuestion()"
        color="primary"
        icon="add"
        label="Question à réponse numérique"
        size="l"
        icon-right="123"
        outline
      />
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useSurveysStore } from "./stores/surveys.store";
import { MultiChoiceQuestionPageQuestion, QuestionsPage } from "./common";
import { computed, nextTick } from "vue";
import draggable from "vuedraggable";
import { v4 as uuid4 } from "uuid";
import {
  duplicateQuestion,
  removeQuestion,
  removeAnswer,
  addAnswer,
} from "./utils";

const surveyStore = useSurveysStore();
const { currentPage } = storeToRefs(surveyStore);

let lastItemExpanded: HTMLElement | null = null;
const conditionalOptions = computed(() => {
  const options = (currentPage.value as QuestionsPage).data.questions
    .filter((question) => question.type === "multichoice")
    .map((question) => {
      return {
        label: `[${question.id}] ${question.body}`,
        value: question.internalID,
      };
    });
  return [
    {
      label: "Aucune",
      value: null,
    },
  ].concat(...options);
});

function getAcceptedAnswersOptions(questionID: string) {
  let questions = (currentPage.value as QuestionsPage).data.questions.filter(
    (question) =>
      question.internalID === questionID && question.type === "multichoice",
  ) as MultiChoiceQuestionPageQuestion[];
  if (questions.length === 0) {
    return [];
  }
  let question = questions[0];
  return question.answers.map((answer) => {
    return {
      label: `[${answer.code}] ${answer.body}`,
      value: answer.internalID,
    };
  });
}

function removeAcceptedAnswer(answers: string[], index: number) {
  answers.splice(index, 1);
}

async function addMultiChoiceQuestion() {
  (currentPage.value as QuestionsPage).data.questions.push({
    ...getDefaultBaseQuestion("multichoice"),
    answers: [],
  } as MultiChoiceQuestionPageQuestion);
  await nextTick();
}

async function addFreeTextQuestion() {
  (currentPage.value as QuestionsPage).data.questions.push({
    ...getDefaultBaseQuestion("string"),
    choice: "",
  });
  await nextTick();
}

async function addNumberQuestion() {
  (currentPage.value as QuestionsPage).data.questions.push({
    ...getDefaultBaseQuestion("number"),
    choice: 0,
  });
  await nextTick();
}

function getDefaultBaseQuestion(type: "multichoice" | "string" | "number") {
  return {
    id: "",
    internalID: uuid4(),
    type,
    body: "",
    choice: null,
    condition: {
      questionID: null,
      answers: [],
    },
  };
}

function addConditionalAnswer(conditions: Array<string | null>) {
  conditions.push(null);
}

function onShow(event) {
  lastItemExpanded = event.target;
}

function afterShow() {
  if (!lastItemExpanded) {
    return;
  }
  lastItemExpanded.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
}
</script>
