import { createApp } from "vue";
import { createPinia } from "pinia";
import { Loading, Notify, Quasar, Dialog } from "quasar";
import { createRouter, createWebHashHistory } from "vue-router";

import "@quasar/extras/material-icons/material-icons.css";
import "quasar/src/css/index.sass";

import App from "./App.vue";
import SurveyPage from "./SurveyPage.vue";
import AdminPage from "./AdminPage.vue";
import CreateSurveyPage from "./CreateSurveyPage.vue";

const routes = [
  { name: "survey", path: "/", component: SurveyPage },
  { name: "admin", path: "/admin", component: AdminPage },
  { name: "edit", path: "/edit", component: CreateSurveyPage },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
const pinia = createPinia();

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(Quasar, { plugins: { Notify, Loading, Dialog } });
app.mount("#q-app");
