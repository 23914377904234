<style module>
.body {
  margin: 0;
  padding: 0;
}

.survey:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.clickable {
  cursor: pointer;
}
</style>
<template>
  <div>
    <q-list bordered class="rounded-borders">
      <q-item-label header>Questionnaires</q-item-label>

      <q-item>
        <q-btn
          @click="createSurvey()"
          icon="add"
          label="Nouveau questionnaire"
          outline
        />
      </q-item>
      <q-separator />
      <q-item v-if="surveys.length === 0"
        >Aucun questionnaire n'est disponible</q-item
      >
      <q-item v-for="survey in surveys" :class="$style.survey">
        <q-item-section
          avatar
          top
          @click="editSurvey(survey)"
          :class="$style.clickable"
        >
          <q-icon name="fact_check" color="black" size="34px" />
        </q-item-section>

        <q-item-section
          top
          @click="editSurvey(survey)"
          :class="$style.clickable"
        >
          <q-item-label>
            <span class="text-weight-medium">{{ survey.title }}</span>
          </q-item-label>
          <q-item-label caption lines="1">
            {{ survey.pages_count }} pages
          </q-item-label>
        </q-item-section>

        <q-item-section top side>
          <div class="text-grey-8 q-gutter-xs">
            <q-btn
              class="gt-xs"
              size="12px"
              flat
              dense
              round
              icon="edit"
              :to="{ path: '/edit', query: { id: survey.id } }"
            >
              <q-tooltip>Modifier</q-tooltip>
            </q-btn>
            <q-btn
              class="gt-xs"
              size="12px"
              flat
              dense
              round
              icon="preview"
              :to="{ path: '/', query: { id: survey.id, u: 'preview' } }"
            >
              <q-tooltip>Prévisualiser</q-tooltip>
            </q-btn>
            <q-btn
              class="gt-xs"
              size="12px"
              flat
              dense
              round
              icon="delete"
              @click="removeSurvey(survey)"
            >
              <q-tooltip>Supprimer</q-tooltip>
            </q-btn>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { SurveySummary } from "./common";
import { sendRequest } from "./services/admin";
import { Loading, Dialog } from "quasar";

const router = useRouter();
const surveys = ref<SurveySummary[]>([]);

async function getSurveys() {
  Loading.show();
  sendRequest({ uri: `/api/surveys` })
    .then(async (response) => {
      surveys.value = (await response.json()) as SurveySummary[];
    })
    .finally(() => Loading.hide());
}

async function createSurvey() {
  Loading.show();
  sendRequest({
    method: "POST",
    uri: `/api/admin/surveys/create`,
  })
    .then(async (response) => {
      const surveyID = await response.text();
      router.push({
        path: "/edit",
        query: {
          id: surveyID,
        },
      });
    })
    .finally(() => Loading.hide());
}

function editSurvey(survey: SurveySummary) {
  router.push({
    path: "/edit",
    query: {
      id: survey.id,
    },
  });
}

async function removeSurvey(survey: SurveySummary) {
  Dialog.create({
    title: "Confirm",
    message: `Supprimer le questionnaire '${survey.title}', ses ${survey.pages_count} questions et les réponses associées ?`,
    cancel: true,
    persistent: true,
  }).onOk(() => {
    Loading.show();
    sendRequest({
      method: "DELETE",
      uri: `/api/admin/surveys/${survey.id}`,
    }).then(async (response) => {
      return getSurveys();
    });
  });
}

onMounted(getSurveys);
</script>
