<style module>
.title_1 {
  font-size: 32px;
}

.title_2 {
  font-size: 24px;
}

.title_3 {
  font-size: 18px;
}

.editor {
  top: 52px;
  position: relative;
}

.question {
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 5px;
  position: relative;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 0 5px;
  padding: 5px 0px;
}

.pages {
  display: flex;
  flex-direction: row;
  gap: 10px 0px;
  align-items: flex-end;
}

.pagesColumn {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}

.pagesColumn .page {
  width: 100%;
  max-width: inherit;
}

.page {
  border-color: var(--border-color);
  border-style: solid;
  border-width: 1px 1px 0 1px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  height: 33px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.page.selected {
  height: 36px;
  font-weight: bold;
  max-width: inherit;
}

.pageActions {
  display: flex;
  justify-content: flex-end;
}

.pageTitle {
  max-width: 300px;
  flex: 1;
}

.selected {
  background-color: var(--select-bg-color) !important;
}

[aria-expanded="true"] {
  background-color: var(--select-bg-color);
}

.floatingToolbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 9;
}
</style>

<template>
  <div
    :class="$style.floatingToolbar"
    class="q-pa-sm q-ma-none row justify-between items-center content-start"
  >
    <h1 class="text-h6 q-pa-none q-ma-none">Modifier un questionnaire</h1>
    <q-btn
      @click="save()"
      color="primary"
      icon="save"
      label="Sauvegarder"
      outline
      size=""
    />
  </div>
  <div v-if="survey" :class="$style.editor">
    <div class="q-pa-sm">
      <q-input
        v-model="survey.title"
        label="Titre du questionnaire"
        autofocus
      ></q-input>
    </div>
    <div>
      <div :class="$style.actions">
        <q-checkbox
          v-model="pagesColumnMode"
          label="Afficher les pages comme liste"
        />
        <q-btn
          @click="showAddPageDialog = true"
          color="primary"
          icon="add"
          label="Ajouter une page"
          size="l"
          outline
        />
      </div>
      <app-info-message
        v-if="survey.pages.length === 0"
        :class="$style.empty"
        message="Aucune page à afficher"
      >
      </app-info-message>
    </div>
    <draggable
      v-model="survey.pages"
      :class="{ [$style.pages]: true, [$style.pagesColumn]: pagesColumnMode }"
      @change="selectPageByIndex(currentPageIndex)"
      item-key="id"
    >
      <template #item="{ element: page, index: pageIndex }">
        <div
          :class="{
            [$style.page]: true,
            [$style.selected]: currentPageIndex === pageIndex,
          }"
          @click="selectPageByIndex(pageIndex)"
          :title="page.title"
        >
          {{ page.title }}
        </div>
      </template>
    </draggable>
    <q-list bordered v-if="currentPage">
      <q-item :class="$style.pageActions">
        <q-btn
          @click="removePage(currentPageIndex)"
          icon="remove"
          label="Supprimer la page"
          color="negative"
          size="l"
          outline
        />
      </q-item>
      <q-item>
        <q-input
          v-model="currentPage.title"
          label="Titre de la page"
          :class="$style.pageTitle"
          :style="{ width: '100%' }"
        ></q-input>
      </q-item>
      <q-item>
        <q-editor v-model="currentPage.description" min-height="5rem" />
      </q-item>
    </q-list>
    <app-questions-page-editor
      :page="currentPage?.id"
      v-if="currentPage && currentPage.type === 'questions'"
    ></app-questions-page-editor>
    <app-table-page-editor
      :page="currentPage?.id"
      v-if="currentPage && currentPage.type === 'table'"
    ></app-table-page-editor>
    <q-card flat>
      <q-card-section>
        <q-btn
          @click="save()"
          color="primary"
          icon="save"
          label="Sauvegarder"
          outline
        />
      </q-card-section>
    </q-card>
    <q-dialog v-model="showAddPageDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Nouvelle page</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input
            v-model="newPageTitle"
            label="Titre de la nouvelle page"
            autofocus
          ></q-input>
          Type de page à ajouter
          <q-option-group
            v-model="newPageType"
            :options="newPageTypeOptions"
            color="primary"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="OK"
            color="primary"
            v-close-popup
            @click="addPage()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { Loading, Notify } from "quasar";
import draggable from "vuedraggable";
import { storeToRefs } from "pinia";

import { PageType } from "./common";
import { sendRequest } from "./services/admin";
import AppInfoMessage from "./AppInfoMessage.vue";
import AppQuestionsPageEditor from "./QuestionsPageEditor.vue";
import AppTablePageEditor from "./TablePageEditor.vue";
import { useSurveysStore } from "./stores/surveys.store";

const surveyStore = useSurveysStore();
const route = useRoute();
const survey_id: string = route.query.id as string;
const showAddPageDialog = ref<boolean>(false);
const pagesColumnMode = ref<boolean>(false);
const newPageType = ref<PageType>("questions");
const newPageTitle = ref<string>("");
const newPageTypeOptions = ref([
  {
    label: "Questions",
    value: "questions",
  },
  {
    label: "Table",
    value: "table",
  },
]);

const { survey, currentPage, currentPageIndex } = storeToRefs(surveyStore);

async function save() {
  Loading.show();
  sendRequest({
    method: "POST",
    uri: `/api/admin/surveys/${survey_id}`,
    body: JSON.stringify(survey.value),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(() => {
      Notify.create({
        color: "positive",
        message: "Sauvegardé",
        icon: "done",
      });
    })
    .catch(() => {
      Notify.create({
        color: "negative",
        message: "Echec de la sauvegarde",
        icon: "report_problem",
      });
    })
    .finally(() => Loading.hide());
}

async function getSurvey() {
  Loading.show({ group: "getSurvey" });

  await surveyStore.loadSurvey(survey_id);

  if (survey.value?.pages.length !== 0) {
    selectPageByIndex(0);
  }

  Loading.hide("getSurvey");
}

function selectPageByIndex(index: number) {
  surveyStore.setCurrentPage(index);
}

function addPage() {
  if (!survey.value) {
    return;
  }

  let data;
  if (newPageType.value === "table") {
    data = {
      answers: [],
      questions: [],
    };
  } else if (newPageType.value === "questions") {
    data = {
      questions: [],
    };
  }
  survey.value?.pages.push({
    title: newPageTitle.value,
    type: newPageType.value,
    data,
  });
  newPageTitle.value = "";
  surveyStore.selectLastPage();
}

function removePage(index: number) {
  survey.value?.pages.splice(index, 1);
  currentPage.value = null;
  currentPageIndex.value = -1;
}

onMounted(getSurvey);
</script>
