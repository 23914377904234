<style module>
.question {
  /* border: solid 1px black; */
  padding: 10px;
  margin-bottom: 5px;
  font-size: 26px;
  text-align: justify;
}

.answers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 26px;
  padding-top: 18px;
}

.answer {
  border: solid 2px transparent;
  border-radius: 8px;
  color: var(--answer-text-color);
  background-color: var(--answer-bg-color);
  padding: 10px;
  margin: 5px;
  position: relative;
  min-width: 65px;
  text-align: center;
  box-shadow: 5px 3px 5px var(--answer-shadow-bg-color);
}

i.answerIcon {
  position: absolute;
  top: -13px;
  right: -10px;
  display: none;
  color: green;
}

.selected {
  background-color: var(--answer-selected-bg-color);
  color: var(--answer-selected-text-color);
  border-color: var(--answer-selected-border-color);
}

i.visible {
  display: inline-block;
}

.answer:hover {
  cursor: pointer;
  background-color: var(--answer-hover-bg-color);
  color: var(--answer-hover-text-color);
}

.actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.page {
  padding: 10px;
}

.questionsArea {
  padding-top: 15px;
}

.questionsTable {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.questionsTable td:nth-child(even) {
  background-color: var(--table-strip-bg-color);
}

.questionsTable tr:hover td {
  background-color: var(--table-row-hover-bg-color) !important;
}

.questionsTable th {
  min-width: fit-content;
  padding: 10px 15px;
}

.questionsTable td {
  padding: 20px 0 20px 10px;
}

.questionsTable tr {
  border-bottom: solid 1px var(--border-color);
}

.questionColumn {
  max-width: 800px;
  padding-right: 10px !important;
  text-align: justify;
}

.answerCell {
  text-align: center;
}

.tableHeader {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 20px;
  z-index: 999;
}

.done {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<template>
  <div v-if="!done && survey && currentPage" :class="$style.page">
    <div class="text-h5">
      {{ survey.title }} <q-icon name="chevron_right" color="primary"></q-icon>
      {{ currentPage.title }}
    </div>
    <div class="text-h6">
      <q-card-section v-html="currentPage.description" />
    </div>
    <div v-if="currentPage?.type == 'questions'" :class="$style.questionsArea">
      <div
        v-for="question in currentPage.data.questions"
        :class="$style.question"
        :key="question.internalID"
      >
        <div
          v-if="shouldShowQuestion(question, currentPage.data.questions)"
          :name="question.body"
        >
          <div>
            <q-icon name="arrow_forward" color="primary"></q-icon>
            <span v-html="question.body"></span>
          </div>
          <div :class="$style.answers" v-if="question.type === 'multichoice'">
            <div
              v-for="answer in question.answers"
              :class="{
                [$style.answer]: true,
                [$style.selected]: question.choice === answer.internalID,
              }"
              @click="selectAnswer(question, answer)"
            >
              <q-icon
                name="task_alt"
                :class="{
                  [$style.answerIcon]: true,
                  [$style.visible]: question.choice === answer.internalID,
                }"
              ></q-icon>
              <div>
                {{ answer.body }}
              </div>
            </div>
          </div>
          <div :class="$style.answers" v-if="question.type === 'string'">
            <q-input v-model.trim="question.choice" label="Réponse"></q-input>
          </div>
          <div :class="$style.answers" v-if="question.type === 'number'">
            <q-input
              v-model.number="question.choice"
              label="Réponse"
              type="number"
            ></q-input>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentPage?.type == 'table'" :class="$style.questionsArea">
      <table :class="$style.questionsTable">
        <tr :class="$style.tableHeader">
          <th :class="$style.questionColumn"></th>
          <th v-for="answer in currentPage.data.answers">{{ answer.body }}</th>
        </tr>
        <tr v-for="question in currentPage.data.questions">
          <td :class="$style.questionColumn">{{ question.body }}</td>
          <td
            v-for="answer in currentPage.data.answers"
            :class="$style.answerCell"
          >
            <q-radio
              v-model="question.choice"
              :val="answer.internalID"
              label=""
              size="xl"
              color="teal"
            />
          </td>
        </tr>
      </table>
    </div>
    <div v-if="somePageQuestionsNotAnswered" :class="$style.done">
      <div>
        <q-icon name="warning" color="red" class="text-h3"></q-icon>
      </div>
      <div class="text-body1">
        Une fois toutes les questions traitées, vous allez pouvoir continuer
      </div>
    </div>
    <div :class="$style.actions">
      <q-btn
        v-if="currentPageIndex !== 0"
        @click="previousPage()"
        color="primary"
        icon="arrow_back"
        label="Précédent"
        size="xl"
      />
      <q-btn
        v-if="currentPageIndex === survey.pages.length - 1"
        @click="save()"
        color="primary"
        icon="save"
        label="Enregistrer"
        size="xl"
        :disable="somePageQuestionsNotAnswered"
      />
      <q-btn
        v-if="currentPageIndex !== survey.pages.length - 1"
        @click="nextPage()"
        color="primary"
        icon="arrow_forward"
        label="Suivant"
        size="xl"
        :disable="somePageQuestionsNotAnswered"
      />
    </div>
  </div>
  <div v-if="done" :class="$style.done">
    <div>
      <q-icon name="person_check" color="teal" class="text-h2"></q-icon>
    </div>
    <div class="text-h2">Merci pour votre participation</div>
  </div>
</template>

<script setup lang="ts">
/**
 * TODO
 * - Interface de création des questionnaires
 * - Export Excel
 * - Travailler sur le visuel
 * - Bonus: grouper les questions
 *  - Suivant/Précédent pour passer d'un groupe à l'autre
 *  - Barre de progression par groupe
 **/
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { Loading, QBtn } from "quasar";

import { SingleQuestion, TablePageQuestion, Answer, Survey } from "./common";

const route = useRoute();
const survey_id = route.query.id;
const user_id = route.query.u;
const done = ref(false);
const survey = ref<Survey | null>(null);
const currentPageIndex = ref<number>(0);
const currentPage = computed(() => {
  return survey.value?.pages[currentPageIndex.value];
});
const somePageQuestionsNotAnswered = computed(() => {
  if (!survey.value || !currentPage.value) {
    return false;
  }

  const value = currentPage.value.data.questions.some((question) => {
    if (currentPage.value?.type === "table") {
      if (question.choice === null) {
        return true;
      }
    } else if (currentPage.value?.type === "questions") {
      if (!shouldShowQuestion(question, currentPage.value.data.questions)) {
        return false;
      }
      if (question.choice === null) {
        return true;
      }
      if (question.type === "string" && question.choice.trim() === "") {
        return true;
      }
      if (question.type === "number" && typeof question.choice !== "number") {
        return true;
      }
    } else {
      throw new Error();
    }
  });
  return value;
});

function shouldShowQuestion(
  question: SingleQuestion,
  pageQuestions: SingleQuestion[],
): boolean {
  if (!question.condition.questionID) {
    return true;
  }

  let targetQuestion = pageQuestions.filter(
    (q) => q.internalID === question.condition.questionID,
  )[0];
  if (!targetQuestion) {
    return true;
  }
  if (question.condition.answers.includes(targetQuestion.choice as string)) {
    return true;
  }

  return false;
}

function selectAnswer(
  question: SingleQuestion | TablePageQuestion,
  answer: Answer,
) {
  question.choice = answer.internalID;
}

function nextPage() {
  currentPageIndex.value += 1;
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function previousPage() {
  currentPageIndex.value -= 1;
  window.scrollTo({ top: 0, behavior: "smooth" });
}

async function save() {
  Loading.show();
  fetch(`/api/surveys/${survey_id}/u/${user_id}`, {
    method: "POST",
    body: JSON.stringify(survey.value),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(() => (done.value = true))
    .finally(() => Loading.hide());
}

async function getSurvey() {
  Loading.show();
  fetch(`/api/surveys/${survey_id}`, {
    method: "GET",
  })
    .then(async (response) => {
      survey.value = (await response.json()) as Survey;
    })
    .finally(() => Loading.hide());
}

onMounted(getSurvey);
</script>
