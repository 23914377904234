<style module></style>
<template>
  <q-list bordered v-if="currentPage && currentPage.type === 'table'">
    <q-item-label header>Réponses possibles</q-item-label>
    <draggable
      v-model="currentPage.data.answers"
      handle=".answer.q-expansion-item .q-item"
      item-key="internalID"
    >
      <template #item="{ element: answer, index }">
        <q-expansion-item
          group="table-answers"
          expand-separator
          icon="fact_check"
          class="answer"
          :label="answer.body"
          :caption="answer.code"
          @show="onShow($event)"
          @after-show="afterShow()"
        >
          <q-card>
            <q-card-actions align="right">
              <q-btn
                @click="removeAnswer(currentPage.data, index)"
                icon="remove"
                label="Supprimer la réponse"
                color="negative"
                outline
              />
            </q-card-actions>
            <q-card-section>
              <q-input
                v-model="answer.body"
                label="Réponse à afficher"
              ></q-input>
              <q-input v-model="answer.code" label="Code interne"></q-input>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </template>
    </draggable>
    <q-item>
      <q-btn
        @click="addAnswer(currentPage.data)"
        icon="add"
        label="Réponse"
        outline
      />
    </q-item>

    <q-separator />
    <q-item-label header>Questions</q-item-label>
    <draggable
      v-model="currentPage.data.questions"
      handle=".question.q-expansion-item .q-item"
      item-key="internalID"
    >
      <template #item="{ element: question, index: qIndex }">
        <q-expansion-item
          expand-separator
          class="question"
          icon="question_mark"
          :label="question.body"
          :caption="question.id"
          group="questions"
          @show="onShow($event)"
          @after-show="afterShow()"
        >
          <q-card>
            <q-card-actions align="right">
              <q-btn
                @click="removeQuestion(currentPage, qIndex)"
                icon="remove"
                label="Supprimer la question"
                color="negative"
                outline
              />
              <q-btn
                @click="duplicateQuestion(currentPage, qIndex)"
                icon="content_copy"
                label="dupliquer la question"
                color="primary"
                outline
              />
            </q-card-actions>
            <q-card-section>
              <q-input
                v-model="question.id"
                label="Identifiant de la question"
              ></q-input>
              <q-input
                v-model="question.body"
                label="Question à afficher"
              ></q-input>
              <q-checkbox
                v-model="question.reversed"
                label="Inversé le score"
              />
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </template>
    </draggable>
    <q-item>
      <q-btn
        @click="addQuestionToTable()"
        color="primary"
        icon="add"
        label="Question"
        size="l"
        outline
      />
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { v4 as uuid4 } from "uuid";
import draggable from "vuedraggable";
import { useSurveysStore } from "./stores/surveys.store";
import {
  duplicateQuestion,
  removeAnswer,
  addAnswer,
  removeQuestion,
} from "./utils";
import { TablePage } from "./common";

const surveyStore = useSurveysStore();
const { currentPage } = storeToRefs(surveyStore);

let lastItemExpanded: HTMLElement | null = null;

function addQuestionToTable() {
  (currentPage.value as TablePage).data.questions.push({
    id: "",
    internalID: uuid4(),
    body: "",
    reversed: false,
    choice: null,
  });
}

function onShow(event) {
  lastItemExpanded = event.target;
}

function afterShow() {
  if (!lastItemExpanded) {
    return;
  }
  lastItemExpanded.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
}
</script>
