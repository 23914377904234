import { ref } from "vue";
import { defineStore } from "pinia";
import { v4 as uuid4 } from "uuid";
import {
  Answer,
  MultiChoiceQuestionPageQuestion,
  Page,
  Question,
  SingleQuestion,
  Survey,
} from "../common";
import { sendRequest } from "../services/admin";

export const useSurveysStore = defineStore("surveys", () => {
  const survey = ref<Survey | null>(null);
  const currentPage = ref<Page | null>(null);
  const currentPageIndex = ref<number>(-1);

  async function loadSurvey(surveyID: string) {
    await sendRequest({ uri: `/api/surveys/${surveyID}` }).then(
      async (response) => {
        survey.value = (await response.json()) as Survey;
        survey.value.pages.forEach(patchPage);
      },
    );
  }

  function setCurrentPage(pageIndex: number) {
    let page = getPageByIndex(pageIndex);
    patchPage(page);
    currentPage.value = page;
    currentPageIndex.value = pageIndex;
  }

  function selectLastPage() {
    if (survey.value === null) {
      throw Error();
    }
    let pageIndex = survey.value.pages.length - 1;
    setCurrentPage(pageIndex);
  }

  function getPageByIndex(index: number): Page {
    return survey.value?.pages[index] as Page;
  }

  function patchSingleQuestion(question: SingleQuestion) {
    if (!question.condition) {
      question.condition = {
        questionID: null,
        answers: [],
      };
    }
    if (!question.type) {
      question.type = "multichoice";
    }
    if (question.type == "multichoice") {
      (question as MultiChoiceQuestionPageQuestion).answers.forEach(
        patchAnswer,
      );
    }
  }
  function patchQuestion(question: Question) {
    if (!question.internalID) {
      question.internalID = uuid4();
    }
  }
  function patchAnswer(answer: Answer) {
    if (!answer.internalID) {
      answer.internalID = uuid4();
    }
  }
  function patchPage(page: Page) {
    if (!page.id) {
      page.id = uuid4();
    }
    if (!page.description) {
      page.description = "";
    }
    page.data.questions.forEach(patchQuestion);
    if (page?.type === "questions") {
      page.data.questions.forEach(patchSingleQuestion);
    } else if (page?.type === "table") {
      page.data.answers.forEach(patchAnswer);
    }
  }

  return {
    survey,
    currentPage,
    currentPageIndex,
    loadSurvey,
    setCurrentPage,
    selectLastPage,
  };
});
