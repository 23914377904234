let login = "";
let password = "";

export function sendRequest({
  method = "GET",
  uri,
  body,
  headers = {},
}: {
  method?: string;
  uri: string;
  body?: string;
  headers?: { [key: string]: string };
}) {
  if (login && password) {
    headers["Authorization"] =
      "Basic " + btoa(unescape(encodeURIComponent(login + ":" + password)));
  }
  return fetch(uri, {
    method,
    body,
    headers,
    signal: AbortSignal.timeout(5000),
  }).then((response) => {
    if (response.status === 401) {
      login = prompt("Login") || "";
      password = prompt("Password") || "";
      return sendRequest({ method, uri, body, headers });
    } else {
      return response;
    }
  });
}
