<style module>
.body {
  margin: 0;
  padding: 0 10px;
}
</style>
<template>
  <router-view :class="$style.body"></router-view>
</template>
<script setup></script>
