import { v4 as uuid4 } from "uuid";

import { MultiChoiceQuestionPageQuestion, Page, TablePageData } from "./common";

export function duplicateQuestion(page: Page, index: number) {
  const questions = page.data.questions;
  if (!questions) {
    return;
  }
  let question = questions[index];
  questions.push({
    ...JSON.parse(JSON.stringify(question)),
    internalID: undefined,
    id: "",
  });
}

export function addAnswer(
  parent: MultiChoiceQuestionPageQuestion | TablePageData,
) {
  parent.answers.push({
    body: "",
    code: "",
    internalID: uuid4(),
  });
}

export function removeAnswer(
  parent: MultiChoiceQuestionPageQuestion | TablePageData,
  index: number,
) {
  parent.answers.splice(index, 1);
}

export function removeQuestion(page: Page, index: number) {
  page.data.questions.splice(index, 1);
}
