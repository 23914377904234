<style module>
.area {
  justify-content: center;
  align-items: center;
}
</style>
<template>
  <q-card flat bordered class="q-pt-md q-pb-md q-mt-sm q-mb-sm">
    <q-card-section horizontal :class="$style.area">
      <q-icon name="info" size="48px" color="blue" />
      <q-card-section>
        {{ message }}
      </q-card-section>
    </q-card-section>
  </q-card>
</template>
<script setup lang="ts">
import { QCard, QCardSection } from "quasar";
const props = defineProps<{
  message: string;
}>();
</script>
